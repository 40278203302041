import { html, LitElement, css } from 'lit';
import { plainToClass } from 'class-transformer';
import { customElement, state, queryAll, property } from 'lit/decorators.js';
import { AWCConfirmationDialog } from '@libraries/akowi-webcomponents/molecules/confirmation-dialog.molecule';

import './preview.view';
import './components/surface.component';
import './components/text-block.component';
import './components/text-input.component';
import './components/font-option.component';
import './components/font-chooser.component';
import './components/color-option.component';
import './components/customization.component';
import './components/color-chooser.component';
import './components/surface-preview.component';
import './components/customization-option.component';

import { styles } from './styles';
import { delay } from './util/delay';
import { PreviewView } from './preview.view';
import { SurfaceComponent } from './components/surface.component';
import { SurfacePreviewComponent } from './components/surface-preview.component';

import {
  Customization,
  CustomizationResponse,
  CustomizeOutput,
  FontOption,
  OrderItemStatusEnum,
  Product,
  Surface,
  TextBlock,
} from '@app/shared';

@customElement('editor-view')
export class EditorView extends LitElement {
  @state()
  private surfaces: {
    [surfaceId: string]: Surface;
  } = {};

  @queryAll('surface-component')
  surfaceComponents: NodeListOf<SurfaceComponent>;

  constructor() {
    super();

    this.fetchProduct();
  }

  @state()
  private existingCustomization: boolean;

  @property()
  private surfaceValidity: { [surfaceId: string]: boolean } = {};

  @state()
  private editMode: boolean;

  @state()
  private inProduction: boolean;

  @state()
  private orderItemStatus: OrderItemStatusEnum;

  private orderItemId: number;

  private externalOrderItemId: string;

  @state()
  private orderId: number;

  @state()
  private externalOrderId: string;

  @state()
  private requestError: boolean;

  @state()
  private position: number;

  @state()
  private positionPreviewImageUri: string;

  async fetchProduct() {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams.entries());
    this.orderItemId = parseInt(params.oiid);
    this.externalOrderItemId = params.eoiid;

    const productRes = await fetch(
      `/api/customizer/${this.orderItemId}/${this.externalOrderItemId}`,
    );

    if (!productRes.ok) {
      this.requestError = true;
      throw new Error(`[${productRes.status}] ${productRes.statusText}`);
    }

    const data = await productRes.json();
    const customizationResponse = plainToClass(CustomizationResponse, data);
    const product = customizationResponse.customizeConfiguration;

    const surfaces = Object.values(product.surfaces);
    const customizations = {};
    for (const surface of surfaces) {
      for (const key of Object.keys(surface.customizations)) {
        customizations[key] = surface.customizations[key];
      }
    }

    this.inProduction = customizationResponse.inProduction;
    this.orderItemStatus = customizationResponse.orderItemStatus;

    this.position = customizationResponse.position;
    this.positionPreviewImageUri = customizationResponse.previewImageUrl;

    this.orderId = customizationResponse.orderId;
    this.externalOrderId = customizationResponse.externalOrderId;

    this.surfaces = product.surfaces;
    await this.loadProductFonts(product);

    await this.updateComplete;

    if (customizationResponse.existingCustomization) {
      this.existingCustomization = true;
      this.applyExistingCustomization(
        customizationResponse.existingCustomization,
      );
    }
  }

  private applyExistingCustomization(customization: CustomizeOutput) {
    const surfaceComponents = Array.from(this.surfaceComponents);

    for (const surface of customization.surfaces) {
      const surfaceComponent = surfaceComponents.find(
        (component) =>
          component.name === surface.name || component.label === surface.name,
      );

      if (!surfaceComponent) continue;

      for (const area of surface.areas) {
        if (area.customizationType === 'TextPrinting') {
          const textBlock = surfaceComponent.textBlocks.find(
            (targetBlock) => targetBlock.name === area.name,
          );
          textBlock.textInput[0].setValue(area.text);
          textBlock.fontChooser[0].select(area.fontFamily);
        }

        if (area.customizationType === 'Options') {
          const customization = surfaceComponent.components.find(
            (component) => component.name === area.name,
          );
          customization.select(area.optionValue);
        }
      }

      surfaceComponent.requestUpdate();
    }

    this.onBuSubmitClicked();
  }

  async loadProductFonts(product: Product) {
    const surfaces = Object.values(product.surfaces);

    const textBlocks: TextBlock[] = [].concat(
      ...surfaces.map((surface) => Object.values(surface.textBlocks)),
    );

    const fonts: FontOption[] = [].concat(
      ...textBlocks.map((textBlock) =>
        Object.values(textBlock.fontChooser.options),
      ),
    );

    const fontDict = {};

    for (const font of fonts) {
      fontDict[font.family] = font.url;
    }

    for (const key of Object.keys(fontDict)) {
      if (fontDict[key]) {
        const fontFace = new FontFace(key, `url(${fontDict[key]})`);
        await fontFace.load();
        document.fonts.add(fontFace);
      }
    }
  }

  generateOutput(): CustomizeOutput {
    const output = new CustomizeOutput();

    output.surfaces = [].concat(
      ...Array.from(this.surfaceComponents).map((surface) =>
        surface.generateOutput(),
      ),
    );

    output.stop = false;

    return output;
  }

  async submitCustomization() {
    const outputData = this.generateOutput();

    const updateResponse = await fetch(
      `/api/customizer/${this.orderItemId}/${this.externalOrderItemId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(outputData),
      },
    );

    if (!updateResponse.ok) {
      throw new Error(
        `[${updateResponse.status}] ${updateResponse.statusText}`,
      );
    }

    const previewView = document.body.querySelector(
      'preview-view',
    ) as PreviewView;
    await previewView.markSuccessAndClose();
    this.existingCustomization = true;
    await delay(1000);
    location.href = `/order?oid=${this.orderId}&eoid=${this.externalOrderId}`;
  }

  private onSubmitCanceled() {
    this.editMode = true;
    const previewView = document.body.querySelector('preview-view');

    document.body.removeChild(previewView);
  }

  private onPrevSurfaceClicked(event: Event) {
    const target = event.target as SurfaceComponent;

    const surfaceWrapper = this.shadowRoot.querySelector('#surfaceWrapper');

    const surfaceComponents = Array.from(this.surfaceComponents);
    const prevSurface = surfaceComponents.find(
      (surface) => surface.surfaceId === target.prevSurfaceId,
    );

    surfaceWrapper.scrollTo({
      top: prevSurface.offsetTop,
      left: prevSurface.offsetLeft,
      behavior: 'smooth',
    });
  }

  private onNextSurfaceClicked(event: Event) {
    const target = event.target as SurfaceComponent;
    const surfaceWrapper = this.shadowRoot.querySelector('#surfaceWrapper');

    const surfaceComponents = Array.from(this.surfaceComponents);
    const nextSurface = surfaceComponents.find(
      (surface) => surface.surfaceId === target.nextSurfaceId,
    );

    surfaceWrapper.scrollTo({
      top: nextSurface.offsetTop,
      left: nextSurface.offsetLeft,
      behavior: 'smooth',
    });
  }

  private async onBuSubmitClicked() {
    const surfaces = Array.from(this.surfaceComponents);

    this.surfaceValidity = {};

    for (const surface of surfaces) {
      surface.markInvalid = true;
      this.surfaceValidity[surface.surfaceId] = surface.validate();
    }

    if (!Object.values(this.surfaceValidity).includes(false)) {
      const previewView = document.createElement('preview-view') as PreviewView;
      previewView.loading = true;
      previewView.existingData = this.existingCustomization;
      previewView.editMode = this.editMode;
      previewView.inProduction = this.inProduction;
      previewView.orderItemStatus = this.orderItemStatus;

      previewView.addEventListener('submitConfirmed', () =>
        this.submitCustomization(),
      );
      previewView.addEventListener('canceled', () => this.onSubmitCanceled());

      for (const surface of this.surfaceComponents) {
        surface.renderPreviewImage();
      }

      document.body.appendChild(previewView);

      const surfaceImages = await Promise.all(
        Array.from(this.surfaceComponents).map(async (surface) => ({
          name: surface.name,
          imageUrl: await surface.renderPreviewImage(),
        })),
      );

      for (const surfaceImage of surfaceImages) {
        const surfacePreviewComponent = document.createElement(
          'surface-preview-component',
        ) as SurfacePreviewComponent;
        surfacePreviewComponent.name = surfaceImage.name;
        surfacePreviewComponent.imageUrl = surfaceImage.imageUrl;
        surfacePreviewComponent.slot = 'previews';
        previewView.appendChild(surfacePreviewComponent);
      }

      previewView.loading = false;
    }
  }

  private async onReturnToOrderViewClicked() {
    const confirmDialogue = new AWCConfirmationDialog(
      'Änderungen die du noch nicht übermittelt hast gehen verloren.',
      {
        headlineText: 'Wirklich zur Bestellübersicht wechseln?',
        confirmationButtonText: 'Ja',
        cancellationButtonText: 'Abbrechen',
      },
    );

    this.shadowRoot.appendChild(confirmDialogue);
    await confirmDialogue.show();

    const confirmationResult = await confirmDialogue.confirmation;

    this.shadowRoot.removeChild(confirmDialogue);

    if (confirmationResult) {
      location.assign(
        `/order?oid=${this.orderId}&eoid=${this.externalOrderId}`,
      );
    }
  }

  private renderCustomizations(customizations: {
    [customizationId: string]: Customization;
  }) {
    const customizationComponents = [];

    for (const key of Object.keys(customizations)) {
      const customization = customizations[key];

      if (/^FlatRatePriceDeltaContainerComponent:/.test(key)) continue;

      const options = [];

      for (const key of Object.keys(customization.options)) {
        const customizationOption = customization.options[key];

        options.push(html`
          <customization-option
            optionId=${key}
            name=${customizationOption.name}
            thumbnailImageUrl=${customizationOption.thumbnailImageUrl}
            overlayImageUrl=${customizationOption.overlayImageUrl}
            slot="options"
          ></customization-option>
        `);
      }

      customizationComponents.push(
        html`<customization-component
          customizationId=${key}
          name=${customization.name}
          label=${customization.label}
          ?required=${customization.isRequired}
          slot="customizations"
          closed
        >
          ${options}
        </customization-component>`,
      );
    }

    return customizationComponents;
  }

  private renderTextBlocks(textBlocks: { [textBlockId: string]: TextBlock }) {
    const textBlockComponents = [];

    for (const key of Object.keys(textBlocks)) {
      const textBlock = textBlocks[key];

      if (/^FlatRatePriceDeltaContainerComponent:/.test(key)) continue;

      const fontOptionComponents = [];

      for (const key of Object.keys(textBlock.fontChooser.options)) {
        const fontOption = textBlock.fontChooser.options[key];

        fontOptionComponents.push(html`
          <font-option-component
            fontOptionId=${key}
            family=${fontOption.family}
            slot="fonts"
            ?selected=${fontOptionComponents.length <= 0}
          ></font-option-component>
        `);
      }

      const colorOptionComponents = [];

      for (const key of Object.keys(textBlock.colorChooser.options)) {
        const colorOption = textBlock.colorChooser.options[key];

        colorOptionComponents.push(html`
          <color-option-component
            colorOptionId=${key}
            name=${colorOption.name}
            value=${colorOption.value}
            slot="options"
            ?selected=${colorOptionComponents.length <= 0}
          ></color-option-component>
        `);
      }

      textBlockComponents.push(html`
        <text-block-component
          slot="textBlocks"
          name=${textBlock.name}
          textBlockId=${key}
          width=${textBlock.width}
          height=${textBlock.height}
          x=${textBlock.x}
          y=${textBlock.y}
          closed
        >
          <text-input-component
            slot="textInput"
            name=${textBlock.textInput?.name}
            label=${textBlock.textInput?.label}
            maxLength=${textBlock.textInput?.maxLength}
            minLength=${textBlock.textInput?.minLength}
            ?required=${textBlock.textInput?.isRequired}
          ></text-input-component>
          <font-chooser-component
            slot="fontChooser"
            name=${textBlock.fontChooser.name}
          >
            ${fontOptionComponents}
          </font-chooser-component>
          <color-chooser-component
            slot="colorChooser"
            name=${textBlock.colorChooser.name}
            label=${textBlock.colorChooser.label}
          >
            ${colorOptionComponents}
          </color-chooser-component>
        </text-block-component>
      `);
    }

    return textBlockComponents;
  }

  private renderSurfaces() {
    const elements = [];

    const keys = Object.keys(this.surfaces);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const surface = this.surfaces[key];

      let prevSurfaceId = null;
      let prevSurfaceName = null;
      let prevSurfaceValid = null;
      let nextSurfaceId = null;
      let nextSurfaceName = null;
      let nextSurfaceValid = null;

      if (i > 0) {
        prevSurfaceId = keys[i - 1];
        prevSurfaceName = this.surfaces[keys[i - 1]].label;
        prevSurfaceValid = this.surfaceValidity[keys[i - 1]];
      }

      if (i + 1 < keys.length) {
        nextSurfaceId = keys[i + 1];
        nextSurfaceName = this.surfaces[keys[i + 1]].label;
        nextSurfaceValid = this.surfaceValidity[keys[i + 1]];
      }

      elements.push(
        html`<surface-component
          surfaceId=${key}
          prevSurfaceId=${prevSurfaceId}
          prevSurfaceName=${prevSurfaceName}
          ?prevSurfaceValid=${prevSurfaceValid}
          nextSurfaceId=${nextSurfaceId}
          nextSurfaceName=${nextSurfaceName}
          ?nextSurfaceValid=${nextSurfaceValid}
          label=${surface.label}
          baseImageUrl=${surface.baseImageUrl}
          name=${surface.name}
          @prevSurfaceClicked=${this.onPrevSurfaceClicked}
          @nextSurfaceClicked=${this.onNextSurfaceClicked}
        >
          ${this.renderCustomizations(surface.customizations)}
          ${this.renderTextBlocks(surface.textBlocks)}
        </surface-component>`,
      );
    }

    return elements;
  }

  private renderInvalidWarning() {
    if (Object.values(this.surfaceValidity).includes(false)) {
      return html`<span id="validityWarning">
        Bitte prüfe die markierten Felder.
      </span>`;
    }
  }

  private renderCustomizationButtons() {
    let message = 'Personalisierung abschließen';

    if (this.existingCustomization) message = 'Änderungen übermitteln';

    if (!this.requestError) {
      return html`
        <button class="submit" @click=${this.onBuSubmitClicked}>
          ${message}
        </button>
      `;
    }
  }

  private renderPositionPreview() {
    return html`
      <div id="positionPreviewWrapper">
        <button
          id="buToOrderView"
          title="Zur Bestellübersicht"
          @click=${this.onReturnToOrderViewClicked}
        >
          <iron-icon icon="chevron-left"></iron-icon>
        </button>
        <div>${this.position}</div>
        <img src=${this.positionPreviewImageUri} />
      </div>
    `;
  }

  private renderHeader() {
    return html`
      <div id="header">
        ${this.renderPositionPreview()}
        <div id="logo"></div>
        <label>Produktkonfigurator</label>
        <div class="customizationButtons">
          ${this.renderCustomizationButtons()}${this.renderInvalidWarning()}
        </div>
      </div>
    `;
  }

  private renderRequestErrorMessage() {
    if (this.requestError) {
      return html`
        <div id="requestErrorWarning">
          <img src="/images/loading_error.jpg" />
          <p>
            Bei dem Versuch deinen Artikel zu laden ist leider ein Fehler
            aufgetreten.
          </p>
          <p>
            Bitte überprüfe den von dir verwendeten Link und versuche es noch
            einmal.
          </p>
          <p>
            Sollte der Fehler weiterhin auftreten kontaktiere uns bitte unter:
            <a href="mailto:info@akowi.com">info@akowi.com</a>
          </p>
        </div>
      `;
    }
  }

  render() {
    return html`
      ${this.renderHeader()}${this.renderRequestErrorMessage()}
      <div id="surfaceWrapper">${this.renderSurfaces()}</div>
      <awc-
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          height: 100vh;
          width: 100vw;
          max-height: 100vh;
          max-width: 100vw;
        }

        #header {
          display: flex;
          padding: 1em;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #ccc;
          height: 70px;
        }

        #header #logo {
          width: 300px;
          height: 100%;
          flex-shrink: 0;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url('/images/logo.png');
          background-position: center;
        }

        #header label {
          font-size: 50px;
        }

        #positionPreviewWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        #positionPreviewWrapper > * {
          padding: 0 0.25em;
          font-size: 15pt;
        }

        #positionPreviewWrapper > #buToOrderView {
          background: none;
          border: none;
          padding: 0;
        }

        #positionPreviewWrapper > #buToOrderView > iron-icon {
          width: 2em;
          height: 2em;
        }

        #surfaceWrapper {
          max-height: calc(100vh - 70px);
          overflow: hidden;
          position: relative;
        }

        #surfaceWrapper surface-component {
          position: relative;
          max-height: calc(100vh - 70px);
          min-height: calc(100vh - 70px);
        }

        #validityWarning {
          color: crimson;
          opacity: 0.75;
        }

        .customizationButtons {
          display: flex;
          flex-direction: column;
        }

        #requestErrorWarning {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 0.25em;
          font-size: 15pt;
        }

        #requestErrorWarning img {
          max-height: 300px;
          width: auto;
        }

        #requestErrorWarning p {
          margin: 0.25em;
        }

        @media screen and (max-width: 1200px) {
          #header label {
            font-size: 25px;
          }
        }

        @media screen and (max-width: 800px) {
          #surfaceWrapper {
            display: flex;
            min-width: 100vw;
            max-width: 100vw;
          }

          #surfaceWrapper surface-component {
            min-width: 100%;
            max-width: 100%;
          }

          #header #logo {
            background-image: url('/images/icon.png');
            width: 40px;
          }
        }

        @media screen and (max-width: 650px) {
          #header {
            justify-content: center;
            height: 160px;
            flex-wrap: wrap;
          }

          #header #logo {
            height: 40px;
          }

          #surfaceWrapper {
            max-height: calc(100vh - 120px);
          }

          #surfaceWrapper surface-component {
            max-height: calc(100vh - 120px);
            min-height: calc(100vh - 120px);
          }
        }

        @media screen and (max-width: 420px) {
          #header {
            padding: 0.25em;
          }
        }
      `,
    ];
  }
}
