import { CustomizeOutput } from './customize-output';
import { OrderItemStatusEnum } from './order-item-status.enum';
import { Product } from './parse-amazon-customize/models/product';

export * from './order-item-status.enum';
export * from './parse-amazon-customize';
export * from './parse-amazon-customize/models/product';
export * from './parse-amazon-customize/models/surface';
export * from './parse-amazon-customize/models/text-block';
export * from './parse-amazon-customize/models/font-option';
export * from './parse-amazon-customize/models/color-option';
export * from './parse-amazon-customize/parse-legacy-format';
export * from './parse-amazon-customize/models/customization';
export * from './parse-amazon-customize/models/customization-option';
export * from './parse-amazon-customize/models/text-input-component';
export * from './parse-amazon-customize/models/font-chooser-component';
export * from './parse-amazon-customize/models/color-chooser-component';

export * from './order-info';
export * from './customize-output';

export class CustomizationResponse {
  customizeConfiguration: Product;
  existingCustomization: CustomizeOutput;
  inProduction: boolean;
  orderItemStatus: OrderItemStatusEnum;
  position: number;
  orderId: number;
  externalOrderId: string;
  previewImageUrl: string;
}
