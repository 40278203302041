import { LitElement, html, css } from 'lit';
import { customElement, property, queryAssignedNodes } from 'lit/decorators.js';

import { styles } from '../styles';
import { FontOptionComponent } from './font-option.component';

@customElement('font-chooser-component')
export class FontChooserComponent extends LitElement {
  @property()
  fontChooserId: string;

  @property()
  name: string;

  @property()
  label: string;

  @property()
  selected: string;

  @queryAssignedNodes({ slot: 'fonts' })
  fontOptions: FontOptionComponent[];

  @property({
    reflect: true,
    type: Boolean,
  })
  opened: boolean;

  @property()
  tabIndex: number;

  connectedCallback() {
    super.connectedCallback();

    if(!this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '0');
      this.tabIndex = 0;
    }
  }

  select(value: string) {
    const targetOption = this.fontOptions.find((option) => option.family === value);

    if (targetOption) {
      this.fontOptions.forEach((option) => option.selected = false);

      this.selected = targetOption.fontOptionId;
      targetOption.selected = true;
      this.dispatchEvent(new Event('change', { bubbles: true }));
      this.blur();
    }
  }

  get selectedOption(): FontOptionComponent {
    return this.fontOptions.find((option) => option.selected);
  }

  private onFontClicked(event: Event) {
    const target = event.target as FontOptionComponent;

    this.fontOptions.forEach((option) => option.selected = false);

    this.selected = target.fontOptionId;
    target.selected = true;
    this.dispatchEvent(new Event('change', { bubbles: true }));
    this.blur();
  }

  private onSlotChanged() {
    if (this.fontOptions.length <= 1) {
      this.style.display = 'none';
    } else {
      this.style.display = 'initial';
    }
    this.requestUpdate();
  }

  render() {
    return html`<label id="title">${this.label}</label>
      <div id="selectWrapper">
        <div id="selectedPreview" style="font-family: '${this.selectedOption?.family}';">
          ${this.selectedOption?.family}
          <iron-icon icon="expand-more"></iron-icon>
        </div>
        <div id="fontsWrapper">
          <slot name="fonts" @click=${this.onFontClicked} @slotchange=${this.onSlotChanged}></slot>
        </div>
      </div>`;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          position: relative;
        }

        :host(:focus) #selectedPreview,
        :host(:hover) #selectedPreview,
        :host(:focus) #fontsWrapper,
        :host(:hover) #fontsWrapper {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }

        #selectWrapper {
          position: relative;
        }

        #fontsWrapper {
          background-color: #efefef;
          display: none;
          position: absolute;
          border-radius: 0.25em;
          overflow: hidden;
          border: 1px solid #bbb;
        }

        :host(:focus) #fontsWrapper {
          display: block;
          width: 100%;
          top: 0;
          left: 0;
        }

        #title {
          font-size: 15pt;
        }

        #selectedPreview {
          font-size: 20px;
          line-height: 20px;
          padding: 0.25em 1em;
          background-color: #eee;
          border-radius: 0.25em;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          border: 1px solid #bbb;
        }
      `,
    ];
  }
}
