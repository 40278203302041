import { TextBlock } from './text-block';
import { Customization } from './customization';

export class Surface {
  name: string;
  label: string;
  instructions?: string;
  baseImageUrl: string;
  customizations: {
    [customizationId: string]: Customization;
  };

  textBlocks: {
    [textBlockId: string]: TextBlock;
  };
}
