export enum OrderItemStatusEnum {
  NEW = 1,
  HAL = 2,
  AUS = 3,
  STO = 4,
  ANG = 5,
  RET = 6,
  EIN = 7,
  NLB = 8,
};
