import 'reflect-metadata';
import {setupStylesheet} from '@libraries/akowi-webcomponents';

import { OrderView } from './order.view';
import { EditorView } from './editor.view';

let activeView;

switch (location.pathname) {
  case '/order':
    activeView = new OrderView();
    break;
  default:
    activeView = new EditorView();
    break;
}

setupStylesheet();

document.body.appendChild(activeView);
