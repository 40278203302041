import { Surface } from './models/surface';
import { Product } from './models/product';
import { TextBlock} from './models/text-block';
import { FontOption } from './models/font-option';
import { ColorOption } from './models/color-option';
import { TextInputComponent } from './models/text-input-component';
import { FontChooserComponent } from './models/font-chooser-component';
import { ColorChooserComponent } from './models/color-chooser-component';

export function parseAmazonLegacy(content: string, baseImageUrl?: string) {
  const jsonOptions = JSON.parse(content);

  const product = new Product();

  const keys = Object.keys(jsonOptions);
  const customizationOptions = keys.filter(key => /print_area_name[0-9]+/.test(key));

  const surface = new Surface();

  product.surfaces = { surface_name1: surface };
  surface.textBlocks = {};
  surface.customizations = {};

  surface.baseImageUrl = baseImageUrl;
  surface.name = jsonOptions['surface_name1'];
  surface.label = jsonOptions['surface_name1'];

  for (let option of customizationOptions) {
    const id = parseInt(option.match(/[0-9]+/)[0]);

    const textBlock = new TextBlock();

    textBlock.name = jsonOptions[`print_area_name${id}`];
    textBlock.width = parseInt(jsonOptions[`design_width_dimension${id}`]);
    textBlock.height = parseInt(jsonOptions[`design_height_dimension${id}`]);
    textBlock.x = parseInt(jsonOptions[`design_x_axis_position${id}`]);
    textBlock.y = parseInt(jsonOptions[`design_y_axis_position${id}`]);

    const inputComponent = new TextInputComponent();
    inputComponent.isRequired = jsonOptions[`is_required${id}`] === 'True';
    inputComponent.maxLength = parseInt(jsonOptions[`character_limit${id}`]);
    inputComponent.label = jsonOptions[`text_area_label_for_customer${id}`];
    textBlock.textInput = inputComponent;

    const fontOption = new FontOption();
    fontOption.family = 'Verdana';
    const fontChooser = new FontChooserComponent();
    fontChooser.label = 'Schriftart';
    fontChooser.options = {
      font1: fontOption,
    };
    textBlock.fontChooser = fontChooser;

    const colorOption = new ColorOption();
    colorOption.name = 'Schwarz';
    colorOption.value = '#000000';
    const colorChooser = new ColorChooserComponent();
    colorChooser.label = 'Schriftfarbe';
    colorChooser.options = {
      color1: colorOption,
    };
    textBlock.colorChooser = colorChooser;

    surface.textBlocks[`print_area_name${id}`] = textBlock;
  }

  return product;
}
