import { Surface } from './surface';

export class Product {
  id: string;
  name: string;
  sku: string;
  surfaces: {
    [surfaceId: string]: Surface;
  };
}
