import { LitElement, html, css } from 'lit';
import { customElement, property, queryAssignedNodes } from 'lit/decorators.js';

import { styles } from '../styles';
import { ColorOptionComponent } from './color-option.component';

@customElement('color-chooser-component')
export class ColorChooserComponent extends LitElement {
  @property()
  name: string;

  @property()
  label: string;

  @queryAssignedNodes({ slot: 'options' })
  options: ColorOptionComponent[];

  get selectedOption(): ColorOptionComponent {
    return this.options.find((option) => option.selected);
  }

  select(value: string) {
    const targetOption = this.options.find((option) => option.name === value);

    if (targetOption) {
      this.options.forEach((option) => (option.selected = false));

      targetOption.selected = true;
      this.requestUpdate();

      this.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }

  private onSlotChanged() {
    if (this.options.length <= 1) {
      this.style.display = 'none';
    } else {
      this.style.display = 'initial';
    }

    this.requestUpdate();
  }

  private onOptionClicked(event: Event) {
    const target = event.target as ColorOptionComponent;

    this.options.forEach((option) => (option.selected = false));

    target.selected = true;
    this.requestUpdate();

    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  render() {
    return html`
      <label id="title">${this.label}<span id="selectedInfo">${this.selectedOption?.name}</span></label>
      <div id="optionsWrapper">
        <slot name="options" @click=${this.onOptionClicked} @slotchange=${this.onSlotChanged}></slot>
      </div>
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        #title {
          font-size: 15pt;
        }

        #selectedInfo {
          color: #555;
          padding: 0 0.25em;
        }
      `,
    ];
  }
}
