import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, queryAssignedNodes, state } from 'lit/decorators.js';

import { styles } from '../styles';
import { TextInputComponent } from './text-input.component';
import { FontChooserComponent } from './font-chooser.component';
import { ColorChooserComponent } from './color-chooser.component';

@customElement('text-block-component')
export class TextBlockComponent extends LitElement {
  @property()
  textBlockId: string;

  @property()
  name: string;

  @property()
  label: string;

  @property({
    type: Number,
  })
  width: number;

  @property({
    type: Number,
  })
  height: number;

  @property({
    type: Number,
  })
  x: number;

  @property({
    type: Number,
  })
  y: number;

  @property({
    reflect: true,
    type: Boolean,
  })
  closed = false;

  private _valid = false;

  @property({
    reflect: true,
    type: Boolean,
  })
  get valid(): boolean {
    return this._valid;
  }

  private _markInvalid: boolean;

  set markInvalid(val: boolean) {
    this._markInvalid = val;
    if (this.textInput[0])
      this.textInput[0].markInvalid = val;

    this.requestUpdate('markInvalid');
  }

  @property({
    reflect: true,
    type: Boolean,
  })
  get markInvalid(): boolean {
    return this._markInvalid;
  }

  @queryAssignedNodes({ slot: 'textInput' })
  textInput: TextInputComponent[];

  @queryAssignedNodes({ slot: 'fontChooser' })
  fontChooser: FontChooserComponent[];

  @queryAssignedNodes({ slot: 'colorChooser' })
  colorChooser: ColorChooserComponent[];

  get text(): string {
    return this.textInput[0].value;
  }

  get fontName() {
    return this.fontChooser[0].selectedOption?.family;
  }

  calculateFontSize() {
    const label = document.createElement('label');

    const fontSize = 20;

    label.innerText = this.text;
    label.style.position = 'fixed';
    label.style.opacity = '0';
    label.style.pointerEvents = 'none';
    label.style.fontFamily = this.fontName;
    label.style.fontSize = fontSize + 'px';

    this.shadowRoot.appendChild(label);

    const textRatio = label.clientWidth / label.clientHeight;
    const boxRatio = this.width / this.height;

    let scaleFactor = 1;
    if (textRatio < boxRatio) {
      scaleFactor = this.height / label.clientHeight;
    } else {
      scaleFactor = this.width / label.clientWidth;
    }

    if (scaleFactor === Infinity)
      return fontSize;

    return fontSize * scaleFactor;
  }

  validate(): boolean {
    this.textInput[0]?.validate()
    this._valid = this.textInput[0]?.valid;

    this.requestUpdate('valid');

    return this._valid;
  }

  open() {
    this.closed = false;
    this.textInput[0]?.focus();
    this.dispatchEvent(new Event('opened', { bubbles: true }));
  }

  close() {
    this.closed = true;
    this.dispatchEvent(new Event('closed', { bubbles: true }));
  }

  private onTextInputChanged() {
    this.calculateFontSize();
    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  private onFontChanged() {
    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  private onColorChanged() {
    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  private onFocus() {
    this.dispatchEvent(new Event('focus', { bubbles: true }));
  }

  private onTextSlotChanged() {
    this.label = this.textInput[0]?.label;
  }

  private onHeaderClicked() {
    if (!this.closed) this.close();
    else this.open();
  }

  private renderValidationInfo(): TemplateResult {
    let element = html``;
    if (this.valid && this.text) {
      element = html`<span id="validationInfo" class="valid" ><iron-icon icon="done"></iron-icon></span>`;
    } else {
      if (this.markInvalid && !this.valid) {
        element = html`<span id="validationInfo" class="invalid" ><iron-icon icon="warning"></iron-icon></span>`;
      }
    }

    return element;
  }

  render() {
    return html`
      <div id="header" @click=${this.onHeaderClicked}>
        <iron-icon id="openIndicator" icon="expand-more"></iron-icon>
        <label id="title">
          ${this.label}
          <span class="requirementInfo">
            ${this.textInput[0]?.required ? '*' : '(optional)'}
          </span>
        </label>
        ${this.renderValidationInfo()}
      </div>
      <div id="inputsWrapper">
        <slot name="textInput"
          @change=${this.onTextInputChanged}
          @focus=${this.onFocus}
          @slotchange=${this.onTextSlotChanged}
        ></slot>
        <slot name="fontChooser" @change=${this.onFontChanged}></slot>
        <slot name="colorChooser" @change=${this.onColorChanged}></slot>
      </div>
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          background: white;
          margin: 0.5em;
          border: 1px solid transparent;
        }

        :host([markInvalid]:not([valid])) {
          border: 1px solid crimson;
          animation: invalidBlink 1 1s;
        }

        #header {
          display: flex;
          font-size: 15pt;
          align-items: center;
        }

        #title {
          flex-grow: 1;
        }

        #header > * {
          padding: 0.25em;
        }

        #header,
        #header * {
          cursor: pointer;
        }

        #inputsWrapper {
          padding: 0.25em;
        }

        :host([closed]) #inputsWrapper {
          display: none;
        }

        #header #openIndicator {
          padding: 0;
        }

        :host([closed]) #header #openIndicator {
          transform: rotate(-90deg);
        }

        .requirementInfo {
          color: #555;
        }

        #validationInfo.valid {
          background-color: green;
          color: white;
        }

        #validationInfo.invalid {
          background-color: crimson;
          color: white;
        }

        @keyframes invalidBlink {
          0%,
          50%,
          100% {
            background-color: white;
          }
          25%,
          75% {
            background-color: rgba(255, 0, 0, 0.3);
          }
        }
      `,
    ];
  }
}
