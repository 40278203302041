import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { styles } from '../styles';

@customElement('color-option-component')
export class ColorOptionComponent extends LitElement {
  @property()
  colorOptionId: string;

  @property()
  name: string;

  @property()
  value: string;

  @property({
    reflect: true,
    type: Boolean,
  })
  selected: boolean;

  render() {
    return html`
      <div id="colorPreview" style="background-color: ${this.value}"></div>
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          margin: 0.25em;
          padding: 0.5em;
          background: white;
          border: 1px solid #bbb;
          display: inline-blocK;
          border-radius: 0.25em;
        }

        :host,
        :host * {
          cursor: pointer;
        }

        :host(:hover) {
          border: 1px solid #aaa;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }

        :host([selected]) {
          border: 1px solid var(--primary-brand-color);
          box-shadow: 0 0 5px var(--primary-brand-color);
        }

        #colorPreview {
          height: 3em;
          width: 3em;
        }
      `,
    ];
  }
}