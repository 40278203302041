import { CustomizationOption } from './customization-option';

export class Customization {
  name: string;
  label: string;
  isRequired: boolean;
  options: {
    [optionId: string]: CustomizationOption;
  };
}
