import '@lottiefiles/lottie-player';
import '@polymer/iron-icons/maps-icons';
import '@libraries/akowi-webcomponents/atoms/checkbox.atom';
import { plainToInstance } from 'class-transformer';
import { customElement, state } from 'lit/decorators.js';
import { LitElement, html, TemplateResult, css } from 'lit';
import { AWCCheckbox } from '@libraries/akowi-webcomponents/atoms/checkbox.atom';
import { AWCConfirmationDialog } from '@libraries/akowi-webcomponents/molecules/confirmation-dialog.molecule';

import { styles } from './styles';

import { OrderInfo, OrderItemInfo, OrderItemStatusEnum } from '@app/shared';

@customElement('order-view')
export class OrderView extends LitElement {
  @state()
  private requestError: boolean;

  @state()
  private orderId: number;

  @state()
  private externalOrderId: string;

  @state()
  private orderItems: OrderItemInfo[];

  @state()
  private customerName: string;

  @state()
  private transferCandidateIds: number[] = [];

  @state()
  private selectedTransferCandidates: number[] = [];

  @state()
  private transferOriginId: number;

  constructor() {
    super();

    this.fetchOrderInfos();
  }

  private async fetchOrderInfos() {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams.entries());
    this.orderId = parseInt(params.oid);
    this.externalOrderId = params.eoid;

    const orderRes = await fetch(
      `/api/orders?oid=${this.orderId}&eoid=${this.externalOrderId}`,
    );

    if (!orderRes.ok) {
      this.requestError = true;
      throw new Error(`[${orderRes.status}] ${orderRes.statusText}`);
    }

    const data = plainToInstance(OrderInfo, await orderRes.json());

    this.orderItems = data.items;
    this.customerName = data.customerName;
  }

  private findTransferCandidates(item: OrderItemInfo): OrderItemInfo[] {
    return this.orderItems.filter(
      (target) =>
        target.customizeConfig?.id === item.customizeConfig.id &&
        target.id !== item.id &&
        !target.inProduction &&
        [OrderItemStatusEnum.ANG, OrderItemStatusEnum.EIN].includes(
          target.status,
        ),
    );
  }

  private markTransferTargets(item: OrderItemInfo) {
    const transferCandidates = this.findTransferCandidates(item);

    this.transferCandidateIds = transferCandidates.map(
      (candidate) => candidate.id,
    );
    this.transferOriginId = item.id;
  }

  private async transferCustomization() {
    const originItem = this.orderItems.find(
      (item) => item.id === this.transferOriginId,
    );

    const transferRes = await fetch('/api/customizer/transfer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        originId: this.transferOriginId,
        originExternalId: originItem.externalId,
        targetIds: this.selectedTransferCandidates,
      }),
    });

    if (!transferRes.ok)
      throw new Error(`[${transferRes.status}] ${transferRes.statusText}`);

    this.transferOriginId = null;
    this.transferCandidateIds = [];
    this.selectedTransferCandidates = [];

    const updatedItems = plainToInstance(
      OrderItemInfo,
      (await transferRes.json()) as any[],
    );

    for (const item of updatedItems) {
      const itemImage = this.shadowRoot.querySelector(
        `.rowWrapper[data-itemId="${item.id}"] img.preview`,
      );
      itemImage?.setAttribute(
        'src',
        `/api/customizer/preview?oiid=${item.id}&eoiid=${
          item.externalId
        }&t=${Date.now()}`,
      );

      const targetIdx = this.orderItems.findIndex(
        (target) => target.id === item.id,
      );
      this.orderItems[targetIdx] = item;
    }

    this.requestUpdate('orderItems');
  }

  private onTransferCandidateCheckboxChanged(id: number, e: Event) {
    const target = e.target as AWCCheckbox;

    if (target.checked) {
      this.selectedTransferCandidates = [
        ...this.selectedTransferCandidates,
        id,
      ];
    } else {
      const itemIdx = this.selectedTransferCandidates.findIndex(
        (target) => target === id,
      );
      this.selectedTransferCandidates.splice(itemIdx);
      this.requestUpdate('selectedTransferCandidates');
    }
  }

  private async onSubmitTransferClicked() {
    const selectedItems = this.orderItems.filter((item) =>
      this.selectedTransferCandidates.includes(item.id),
    );
    const customizedItems = selectedItems.filter((item) => item.isCustomized);

    if (customizedItems.length > 0) {
      const customizedPositions = customizedItems.map((item) => item.position);

      let confirmationText = `Die Position ${customizedPositions.join()} hat bereits Anpassungen.\nMöchtest du diese wirklich überschreiben?`;

      if (customizedPositions.length > 1) {
        confirmationText = `Die Positionen ${customizedPositions.join()} haben bereits Anpassungen.\nMöchtest du diese wirklich überschreiben?`;
      }

      const confirmDialogue = new AWCConfirmationDialog(confirmationText, {
        confirmationButtonText: 'Ja überschreiben',
        cancellationButtonText: 'Abbrechen',
      });

      document.body.appendChild(confirmDialogue);

      const confirmationResult = await confirmDialogue.confirmation;
      document.body.removeChild(confirmDialogue);

      if (!confirmationResult) return;
    }

    this.transferCustomization();
  }

  private renderItemCustomizedIndicator(item: OrderItemInfo): TemplateResult {
    let icon = '';
    let text = '';
    let iconClass = '';
    let backgroundColor = 'transparent';

    if (item.isCustomized) {
      icon = 'done';
      text = 'Bereits personalisiert';
      backgroundColor = 'green';
    }

    if (item.inProduction) {
      icon = 'settings';
      iconClass = 'rotate';
      text = 'In der Produktion';
      backgroundColor = 'green';
    }

    if (item.status === OrderItemStatusEnum.AUS) {
      icon = 'maps:local-shipping';
      text = 'Bereits versandt';
      iconClass = 'shipped';
      backgroundColor = 'green';
    }

    if (item.status === OrderItemStatusEnum.STO) {
      icon = 'cancel';
      text = 'Storniert';
      backgroundColor = 'crimson';
    }

    if (item.status === OrderItemStatusEnum.RET) {
      icon = 'assignment-return';
      text = 'Retourniert';
      backgroundColor = 'cornflowerblue';
    }

    return html`
      <div
        style="background-color: ${backgroundColor};"
        class="customizedIndicator"
        title=${text}
      >
        ${item.status === OrderItemStatusEnum.AUS
          ? html`<lottie-player
              autoplay
              loop
              mode="normal"
              src="/lottiefiles/delivery_truck.json"
              style="width: 80px;"
            ></lottie-player>`
          : html`<iron-icon icon=${icon} class=${iconClass}></iron-icon>`}
      </div>
    `;
  }

  private renderTransferButton(item: OrderItemInfo) {
    if (!this.transferOriginId) {
      if (item.isCustomized) {
        if (this.findTransferCandidates(item).length > 0) {
          return html`
            <div>
              <button @click=${this.markTransferTargets.bind(this, item)}>
                Daten kopieren
              </button>
            </div>
          `;
        }
      }

      return html`<div></div>`;
    }
  }

  private renderItemCustomizeButton(item: OrderItemInfo): TemplateResult {
    let message = 'Jetzt personalisieren';

    if (item.isCustomized) {
      message = 'Personalisierung bearbeiten';
    }

    if (
      !item.inProduction &&
      [
        OrderItemStatusEnum.ANG,
        OrderItemStatusEnum.EIN,
        OrderItemStatusEnum.HAL,
      ].includes(item.status)
    ) {
      return html`
        <a
          class="button submit"
          href="/?oiid=${item.id}&eoiid=${item.externalId}"
          >${message}
        </a>
      `;
    }
  }

  private renderCustomizePreview(item: OrderItemInfo): TemplateResult {
    if (item.isCustomized) {
      return html`
        <img
          class="preview"
          src="/api/customizer/preview?oiid=${item.id}&eoiid=${item.externalId}"
          alt="Vorschaubild Position ${item.position}"
        />
      `;
    }
  }

  private renderTransferCheckbox(item: OrderItemInfo) {
    if (this.transferOriginId) {
      if (this.transferCandidateIds.includes(item.id)) {
        return html`
          <awc-checkbox
            @change=${this.onTransferCandidateCheckboxChanged.bind(
              this,
              item.id,
            )}
          ></awc-checkbox>
        `;
      }
      return html`<div></div>`;
    }
  }

  private renderTransferModeInfo() {
    if (this.transferOriginId) {
      const originItem = this.orderItems.find(
        (item) => item.id === this.transferOriginId,
      );

      let selectedText = `${this.selectedTransferCandidates.length} Positionen für die Datenübertragung ausgewählt.`;

      if (this.selectedTransferCandidates.length === 1) {
        selectedText = `${this.selectedTransferCandidates.length} Position für die Datenübertragung ausgewählt.`;
      }

      return html`
        <div id="transferModeInfo">
          <div>
            Markiere die Positionen in welche du die Daten von Position
            ${originItem.position} einfügen möchtest.
          </div>
          <div>
            ${selectedText}
            <button
              class="submit"
              ?disabled=${this.selectedTransferCandidates.length <= 0}
              @click=${this.onSubmitTransferClicked}
            >
              Daten einfügen
            </button>
          </div>
        </div>
      `;
    }
  }

  private renderOrderItems(): TemplateResult {
    const itemElements = [];

    for (let i = 0; i < this.orderItems?.length; i++) {
      const item = this.orderItems[i];

      if (!item.customizeConfig) continue;

      const classList = ['rowWrapper'];

      if (this.transferCandidateIds.includes(item.id)) {
        classList.push('transferCandidate');
        if (this.selectedTransferCandidates.includes(item.id))
          classList.push('selected');
      }

      if (this.transferOriginId === item.id) classList.push('transferOrigin');

      itemElements.push(html`
        <div class=${classList.join(' ')} data-itemId=${item.id}>
          ${this.renderTransferCheckbox(item)}
          <div class="positionIndicator">${i + 1}</div>
          <div>
            <img
              src=${item.previewImageUrl}
              alt="Produktbild Position ${i + 1}"
            />
          </div>
          <div>${this.renderItemCustomizeButton(item)}</div>
          <div>${this.renderCustomizePreview(item)}</div>
          ${this.renderItemCustomizedIndicator(item)}
          ${this.renderTransferButton(item)}
        </div>
      `);
    }

    return html`
      ${this.renderTransferModeInfo()}
      <div
        id="itemTable"
        class="${this.transferOriginId ? 'transferMode' : ''}"
      >
        ${itemElements}
      </div>
    `;
  }

  private renderInfoText() {
    let textContent = html`
      <p>
        Hey ${this.customerName}, hier siehst du deine Artikel und kannst diese
        personalisieren!
      </p>
      <p>
        Klicke einfach bei einer der unten stehenden Positionen auf "Jetzt
        personalisieren"
      </p>
    `;

    const itemsInProduction = this.orderItems?.filter(
      (item) => item.inProduction,
    );

    if (itemsInProduction?.length === this.orderItems?.length) {
      textContent = html`
        <p>
          Hey ${this.customerName}, deine komplette Bestellung wird bereits
          produziert und ist bald schon auf dem Weg zu dir!
        </p>
      `;
    }

    const shippedItems = this.orderItems?.filter(
      (item) => item.status === OrderItemStatusEnum.AUS,
    );

    if (shippedItems?.length === this.orderItems?.length) {
      textContent = html`
        <p>
          Hey ${this.customerName}, deine Bestellung wurde bereits verschickt
          und ist schon bald bei dir!
        </p>
      `;
    }

    return html`<div id="infoTextWrapper">${textContent}</div>`;
  }

  render() {
    return html`
      <img id="companyLogo" alt="Akowi Logo" src="/images/logo.png" />
      ${this.renderInfoText()}${this.renderOrderItems()}
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          padding: 1em;
          max-width: 100vw;
          max-height: 100vh;
          overflow: auto;
        }

        #infoTextWrapper {
          padding: 1em 0;
        }

        #infoTextWrapper p {
          margin: 0.25em 0;
        }

        #companyLogo {
          max-width: 300px;
          align-self: center;
        }

        #transferModeInfo {
          display: flex;
          border: 1px solid #333;
          background-color: var(--primary-brand-color);
          padding: 0.25em 1em;
          transform-origin: bottom center;
          animation: scale-in 1 100ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
          flex-direction: column;
        }

        #transferModeInfo > div:nth-child(2) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        #itemTable {
          border-spacing: 0;
          display: grid;
          grid-template-columns: 1fr 1fr auto 1fr 1fr auto;
        }

        #itemTable.transferMode {
          grid-template-columns: 1fr 1fr 1fr auto 1fr 1fr;
        }

        #itemTable.transferMode
          .rowWrapper:not(.transferOrigin, .transferCandidate) {
          opacity: 0.75;
        }

        #itemTable .rowWrapper {
          display: contents;
        }

        #itemTable .rowWrapper awc-checkbox {
          font-size: 15pt;
        }

        #itemTable .rowWrapper.transferOrigin,
        #itemTable .rowWrapper.transferOrigin > * {
          border-color: #333;
          background-color: var(--primary-brand-color);
        }

        #itemTable .rowWrapper.transferCandidate.selected > * {
          border-color: green;
        }

        #itemTable .rowWrapper.transferCandidate.selected awc-checkbox {
          color: green;
        }

        #itemTable .rowWrapper > * {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }

        #itemTable .rowWrapper > * > * {
          margin: 0.25em;
        }

        #itemTable .rowWrapper:hover > * {
          border-left-color: #333;
          border-left-width: 4px;
        }

        #itemTable .rowWrapper > * {
          border: 1px solid #aaa;
        }

        #itemTable .rowWrapper > *:not(:first-child) {
          border-left: none;
        }

        #itemTable .rowWrapper > *:not(:last-child) {
          border-right: none;
        }

        #itemTable .rowWrapper:not(:last-of-type, .transferOrigin) > * {
          border-bottom: none;
        }

        #itemTable .rowWrapper .positionIndicator {
          text-align: center;
          font-size: 20pt;
        }

        #itemTable .customizedIndicator {
          color: white;
          overflow: hidden;
        }

        #itemTable .customizedIndicator iron-icon {
          width: 30pt;
          height: 30pt;
        }

        #itemTable .customizedIndicator iron-icon.rotate {
          animation: rotate infinite 3s linear;
        }

        #itemTable .customizedIndicator iron-icon.shipped {
          transform-origin: bottom right;
          animation: driveOff infinite 6s ease-in-out 2s;
        }

        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes driveOff {
          0%,
          50% {
            transform: translateX(0) skew(0deg);
          }

          25% {
            transform: translateX(20cm) skew(10deg);
            opacity: 1;
          }

          25.1% {
            transform: translateX(20cm) skew(10deg);
            opacity: 0;
          }

          25.9% {
            transform: translateX(-20cm) skew(10deg);
            opacity: 0;
          }

          26% {
            transform: translateX(-20cm) skew(10deg);
            opacity: 1;
          }

          40% {
            transform: translateX(0) skew(-10deg);
          }
        }

        @keyframes scale-in {
          0% {
            transform: scaleY(0);
          }
          100% {
            transform: scaleY(1);
          }
        }

        @media screen and (min-width: 1000px) {
          :host {
            align-items: center;
          }
        }

        @media screen and (max-width: 850px) {
          #transferModeInfo {
            position: sticky;
            top: -1em;
            z-index: 999;
          }

          #itemTable {
            display: flex;
            flex-direction: column;
          }

          #itemTable .rowWrapper {
            display: grid;
            grid-template-rows: 1fr 1fr auto 1fr 1fr;
            border: 1px solid #aaa;
            margin-bottom: 0.25em;
          }

          #itemTable .rowWrapper > * {
            border: none;
          }

          #itemTable .rowWrapper > *::before {
            content: none;
          }
        }

        @media screen and (max-width: 400px) {
          #companyLogo {
            max-width: calc(100vw - 4em);
          }
        }
      `,
    ];
  }
}
