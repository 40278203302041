import '@polymer/iron-icons';
import { OrderItemStatusEnum } from '@app/shared';
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { styles } from './styles';

@customElement('preview-view')
export class PreviewView extends LitElement {
  @property({
    reflect: true,
    type: Boolean,
  })
  loading: boolean;

  @property({
    type: Boolean,
  })
  existingData: boolean;

  @property({
    type: Boolean,
  })
  editMode: boolean;

  @property({
    type: Boolean,
  })
  inProduction: boolean;

  @property({
    type: Number,
  })
  orderItemStatus: OrderItemStatusEnum;

  private onBuCancelClicked() {
    this.dispatchEvent(new Event('canceled'));
  }

  private onBuSubmitClicked() {
    this.dispatchEvent(new Event('submitConfirmed'));
  }

  async markSuccessAndClose() {
    const successOverlay = document.createElement('div');
    successOverlay.id = 'successOverlay';
    const successOverlayIcon = document.createElement('iron-icon') as any;
    successOverlayIcon.icon = 'done';
    const successIconWrapper = document.createElement('div');
    successIconWrapper.id = 'successIconWrapper';
    const successInfoText = document.createElement('label');
    successInfoText.innerText =
      'Deine Personalisierung wurde an uns übermittelt.\nDu wirst gleich zur Bestellübersicht weitergeleitet.';
    const successInfoTextWrapper = document.createElement('div');
    successInfoTextWrapper.id = 'successInfoTextWrapper';
    successInfoTextWrapper.appendChild(successInfoText);

    successIconWrapper.appendChild(successOverlayIcon);
    successOverlay.appendChild(successIconWrapper);
    successOverlay.appendChild(successInfoTextWrapper);
    const contentWrapper = this.shadowRoot.getElementById('contentWrapper');

    contentWrapper.appendChild(successOverlay);

    const overlayAnimation = successOverlay.animate(
      [{ opacity: 0 }, { opacity: 1 }],
      { duration: 100, iterations: 1 },
    );

    await overlayAnimation.finished;

    successOverlay.style.opacity = '1';

    const iconAnimation = successIconWrapper.animate(
      [
        { transform: 'scale(0) rotate(120deg)' },
        { transform: 'scale(1.2) rotate(-20deg)' },
        { transform: 'scale(1) rotate(0deg)' },
      ],
      {
        duration: 200,
        iterations: 1,
      },
    );

    await iconAnimation.finished;
    successIconWrapper.style.transform = 'scale(1)';

    this.existingData = true;
    this.editMode = false;

    await this.updateComplete;
  }

  private renderSubmitButton() {
    let message = 'Ja, Personalisierung abschließen.';

    if (this.existingData) message = 'Ja, Änderungen übermitteln';

    if ((this.existingData && this.editMode) || !this.existingData)
      return html`<button @click=${this.onBuSubmitClicked} id="buSubmit">
        ${message}
      </button>`;
  }

  private renderHeader() {
    let message = 'Bist du mit deiner Personalisierung zufrieden?';

    if (this.existingData)
      message = 'Deine Personalisierung wurde wie folgt an uns übermittelt.';

    if (this.existingData && this.editMode)
      message = 'Bist du mit deinen Änderungen zufrieden?';

    if (this.inProduction)
      message = 'Dein Artikel befindet sich bereits in der Produktion.';

    if (this.orderItemStatus === OrderItemStatusEnum.AUS)
      message = 'Dein Artikel wurde bereits an dich versandt.';

    if (this.orderItemStatus === OrderItemStatusEnum.STO)
      message = 'Der Artikel wurde storniert.';

    if (this.orderItemStatus === OrderItemStatusEnum.RET)
      message = 'Der Artikel wurde retourniert.';

    return html`<div id="header"><label>${message}</label></div>`;
  }

  private renderButtons() {
    if (
      [OrderItemStatusEnum.ANG, OrderItemStatusEnum.HAL].includes(
        this.orderItemStatus,
      ) &&
      !this.inProduction
    ) {
      return html`
        <div id="controlsWrapper">
          <button @click=${this.onBuCancelClicked}>Nochmal bearbeiten</button>
          ${this.renderSubmitButton()}
        </div>
      `;
    }
  }

  render() {
    return html`
      <div id="contentWrapper">
        ${this.renderHeader()}
        <div id="previewWrapper">
          <slot name="previews"></slot>
          <div class="loadingOverlay">
            <div class="loadingIndicatorWrapper">
              <div></div>
            </div>
            <label>
              Deine Produktvorschau wird generiert, bitte warte einen Moment...
            </label>
          </div>
        </div>
        ${this.renderButtons()}
      </div>
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          position: fixed;
          width: 100dvw;
          height: 100dvh;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 99999;
          box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
          backdrop-filter: blur(10px);
          animation: blendIn 1 0.3s;
        }

        #header {
          font-size: 18pt;
          padding: 0.5em;
        }

        #header label {
          white-space: pre-wrap;
        }

        #contentWrapper {
          position: relative;
          background-color: white;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          border-radius: 0.25em;
          overflow: hidden;
          overflow: auto;
          max-height: calc(100dvh - 1rem);
          display: grid;
          grid-template-rows: 1fr auto 1fr;
        }

        #controlsWrapper {
          display: flex;
          align-items: center;
          font-size: 15pt;
        }

        #controlsWrapper label {
          padding: 0 0.25em;
        }

        #buSubmit {
          background-color: gold;
        }

        #previewWrapper {
          background-color: #eee;
          padding: 0.25em;
          display: flex;
          justify-content: space-around;
          min-height: 300px;
          max-height: 70vh;
          overflow: auto;
          position: relative;
          flex-wrap: wrap;
        }

        .loadingOverlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          backdrop-filter: blur(10px);
          opacity: 1;
          transition: opacity 0.1s;
        }

        :host(:not([loading])) .loadingOverlay {
          opacity: 0;
        }

        .loadingIndicatorWrapper {
          height: 100px;
          width: 100px;
          position: relative;
        }

        .loadingIndicatorWrapper div {
          width: 0%;
          height: 0%;
          border-radius: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          animation: growOscillate infinite 1s;
          position: absolute;
          left: 50%;
          top: 50%;
        }

        .loadingIndicatorWrapper div::after {
          content: '';
          position: absolute;
          width: 0%;
          height: 0%;
          border-radius: 100%;
          background-color: rgba(255, 255, 255, 0.3);
          animation: growOscillate infinite 1s;
          left: 50%;
          top: 50%;
        }

        #successOverlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          backdrop-filter: blur(5px);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          flex-direction: column;
        }

        #successOverlay #successIconWrapper {
          background-color: green;
          border-radius: 100%;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
          transform: scale(0);
          color: white;
        }

        #successOverlay #successIconWrapper iron-icon {
          height: 75pt;
          width: 75pt;
        }

        #successInfoTextWrapper {
          background-color: rgba(0, 0, 0, 0.3);
          color: white;
          padding: 0.5em;
          margin: 0.5em;
          border-radius: 0.25em;
        }

        #successInfoTextWrapper label {
          text-align: center;
          font-size: 15pt;
        }

        @keyframes blendIn {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        @keyframes growOscillate {
          0%,
          100% {
            width: 25%;
            height: 25%;
            margin: -12.5%;
          }

          75% {
            width: 100%;
            height: 100%;
            margin: -50%;
          }
        }

        @media screen and (max-width: 1100px) {
          #controlsWrapper {
            flex-direction: column;
            align-items: stretch;
            padding-top: 1em;
            text-align: center;
          }

          @media screen and (max-height: 1100px) {
            #previewWrapper {
              max-height: 500px;
              overflow: auto;
            }
          }
        }
      `,
    ];
  }
}
