import { TextInputComponent } from './text-input-component';
import { FontChooserComponent } from './font-chooser-component';
import { ColorChooserComponent } from './color-chooser-component';

export class TextBlock {
  name: string;
  fontChooser: FontChooserComponent;
  colorChooser: ColorChooserComponent;
  textInput: TextInputComponent;
  height: number;
  width: number;
  x: number;
  y: number;
  isFreePlacement: boolean;
}
