import { OrderItemStatusEnum, Product } from '.';

export class OrderInfo {
  id: number;
  customerName: string;
  items: OrderItemInfo[];
}

export class OrderItemInfo {
  id: number;
  externalId: string;
  articleId: number;
  previewImageUrl: string;
  isCustomized: boolean;
  customizeConfig: Product;
  status: OrderItemStatusEnum;
  inProduction: boolean;
  position?: number;
}
