import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { styles } from '../styles';

@customElement('customization-option')
export class CustomizationOptionComponent extends LitElement {
  @property()
  optionId: string;

  @property({
    reflect: true,
    type: Boolean,
  })
  selected: boolean;

  @property()
  name: string;

  @property()
  thumbnailImageUrl: string;

  @property()
  overlayImageUrl: string;

  private renderThumbnailImage() {
    let element = html`<div id="thumbnailPlaceholder"></div>`;
    if (this.thumbnailImageUrl)
      element = html`<img src=${this.thumbnailImageUrl} />`;

    return element;
  }

  render() {
    return html`
      ${this.renderThumbnailImage()}
      <label>${this.name}</label>
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          border: 1px solid #bbb;
          border-radius: 0.25em;
          margin: 0.25em;
          width: 120px;
          text-align: center;
          padding: 0.25em;
        }

        :host,
        :host * {
          cursor: pointer;
        }

        :host(:hover) {
          border: 1px solid #aaa;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }

        :host([selected]) {
          border: 1px solid var(--primary-brand-color);
          box-shadow: 0 0 5px var(--primary-brand-color);
        }

        img,
        #thumbnailPlaceholder {
          width: 100px;
          height: 100px;
        }

        #thumbnailPlaceholder {
          background: repeating-linear-gradient(
            -45deg,
            #cecece 0,
            #cecece 10px,
            transparent 10px,
            transparent 20px
          );
        }
      `,
    ];
  }
}
