import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { styles } from '../styles';

@customElement('text-input-component')
export class TextInputComponent extends LitElement {
  @property()
  name: string;

  @property()
  label: string;

  @property({
    reflect: true,
    type: Boolean,
  })
  required: boolean;

  @property()
  maxLines: number;

  @property()
  minLength: number;

  @property()
  maxLength: number;

  @property()
  value = '';

  @state()
  private _valid = false;

  @property({
    reflect: true,
    type: Boolean,
  })
  get valid(): boolean {
    return this._valid;
  }

  @state()
  private charCount = 0;

  @property({
    reflect: true,
    type: Boolean,
  })
  markInvalid: boolean;

  setValue(value: string) {
    this.value = value;
    this.charCount = this.value.length;

    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  async focus() {
    await this.updateComplete;
    this.shadowRoot.querySelector('input')?.focus();
  }

  get isValid(): boolean {
    const trimmedVal = this.value?.trim() || '';

    if (this.required
      && (trimmedVal.length < this.minLength || trimmedVal.length > this.maxLength)
    ) {
      return false;
    }

    return true;
  }

  validate() {
    this._valid = true;

    if (this.required && this.value.length <= 0)
      this._valid = false;

    if (this.value.length > this.maxLength)
      this._valid = false;

    this.requestUpdate('valid');
  }

  private onInputChanged(event: Event) {
    const target = event.target as HTMLInputElement | HTMLTextAreaElement;

    this.value = target.value;
    this.charCount = this.value.length;

    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  private onFocus() {
    this.dispatchEvent(new Event('focus', { bubbles: true }));
  }

  private onKeyEvent(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement
    if (target.value.length >= this.maxLength && event.key.length <= 1) event.preventDefault();
    this.onInputChanged(event);
  }

  render() {
    return html`
      <input
        type="text"
        @change=${this.onInputChanged}
        @keyup=${this.onKeyEvent}
        @keydown=${this.onKeyEvent}
        @focus=${this.onFocus}
        .value=${this.value}
      />
      <label id="charCountIndicator">
        ${this.charCount} von ${this.maxLength} Zeichen
      </label>
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          max-width: 300px;
        }

        #title {
          font-size: 15pt;
        }

        #charCountIndicator {
          text-align: right;
          color: #555;
        }

        .requirementInfo {
          color: #555;
        }

        input {
          border: 1px solid #bbb;
          outline: none;
          font-size: 15pt;
        }

        input:focus {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }

        :host([markInvalid]:not([valid])) input {
          border: 1px solid crimson;
        }

        :host([markInvalid]:not([valid])) input:focus {
          box-shadow: 0 0 5px crimson;
        }
      `,
    ];
  }
}
