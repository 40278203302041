import * as Konva from 'konva';

// @ts-ignore
export function konvaImageFromURL(url: string): Promise<Konva.Image> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    Konva.Image.fromURL(
      url,
      // @ts-ignore
      (image: Konva.Image) => {
        resolve(image);
      },
      (err) => reject(err),
    );
  });
}
