import { Product } from './models/product';
import { Surface } from './models/surface';
import { TextBlock } from './models/text-block';
import { FontOption } from './models/font-option';
import { ColorOption } from './models/color-option';
import { Customization } from './models/customization';
import { TextInputComponent } from './models/text-input-component';
import { CustomizationOption } from './models/customization-option';
import { FontChooserComponent } from './models/font-chooser-component';
import { ColorChooserComponent } from './models/color-chooser-component';

function splitUpLines(content: string): string[][] {
  const lines = content.split('\n');

  const splittedLines = [];

  for (const line of lines) {
    const lineParts = line.split('\t');
    splittedLines.push(lineParts);
  }

  const infos = [];

  for (let i = 0; i < splittedLines[0].length; i++) {
    infos.push([
      splittedLines[0][i],
      splittedLines[1][i],
      splittedLines[2][i],
      splittedLines[3][i],
    ]);
  }

  return infos;
}

function extractPreProcessObjects(strings: string[][]): { [key: string]: string }[] {
  let objects = [];

  let currentObject = null;

  for (let i = 0; i < strings.length; i++) {
    if (strings[i][0].trim() !== '') {
      if (currentObject)
        objects.push(currentObject);
      currentObject = {};
      currentObject.id = strings[i][0];
      currentObject.technicalName = strings[i][0].split(':')[0];
    }

    currentObject[strings[i][1].trim()] = strings[i][3].trim();
  }

  objects.push(currentObject);

  return objects;
}

function isSurface(object: any): boolean {
  return object['baseImage.imageUrl'];
}

export function parseAmazonCustomize(content: string): Product {
  const infos = splitUpLines(content);
  const objects = extractPreProcessObjects(infos);

  const product = new Product();

  product.id = objects[0].id;
  product.sku = objects[0]['Seller Sku'];
  product.name = objects[0].technicalName;
  product.surfaces = {};

  let currentSurface: Surface = null;
  let currentTextBlock: TextBlock = null;
  let prevTextBlock: TextBlock = null;
  let currentCustomizeComponent: Customization = null;

  for (let i = 1; i < objects.length; i++) {
    const object = objects[i];

    if (isSurface(object)) {
      currentSurface = new Surface();

      currentSurface.name = object.technicalName;
      currentSurface.label = object.label;
      currentSurface.instructions = object.instructions;
      currentSurface.baseImageUrl = object['baseImage.imageUrl'];

      currentSurface.customizations = {};
      currentSurface.textBlocks = {};

      product.surfaces[object.id] = currentSurface;

      continue;
    }

    if (object.technicalName === 'FontChooserComponent') {
      if (!currentTextBlock)
        currentTextBlock = new TextBlock();

      currentTextBlock.fontChooser = new FontChooserComponent();
      currentTextBlock.fontChooser.name = object.technicalName;
      currentTextBlock.fontChooser.label = object.label;
      currentTextBlock.fontChooser.options = {};

      continue;
    }

    if (object.technicalName === 'fontOption') {
      const newFontOption = new FontOption();
      newFontOption.name = object.technicalName;
      newFontOption.family = object.family;
      newFontOption.url = object['files.truetype.url'];

      currentTextBlock.fontChooser.options[object.id] = newFontOption;

      continue;
    }

    if (object.technicalName === 'ColorChooserComponent') {
      if (!currentTextBlock)
        currentTextBlock = new TextBlock();

      currentTextBlock.colorChooser = new ColorChooserComponent();
      currentTextBlock.colorChooser.name = object.technicalName;
      currentTextBlock.colorChooser.label = object.label;
      currentTextBlock.colorChooser.options = {};

      continue;
    }

    if (object.technicalName === 'colorOption') {
      const newColorOption = new ColorOption();
      newColorOption.name = object.name;
      newColorOption.value = object.value;

      currentTextBlock.colorChooser.options[object.id] = newColorOption;

      continue;
    }

    if (object.technicalName === 'TextInputComponent') {
      if (!currentTextBlock) {
        currentTextBlock = new TextBlock();
        currentTextBlock.colorChooser = prevTextBlock.colorChooser;
        currentTextBlock.fontChooser = prevTextBlock.fontChooser;
      }

      currentTextBlock.textInput = new TextInputComponent();
      currentTextBlock.textInput.name = object.technicalName;
      currentTextBlock.textInput.label = object.label;
      currentTextBlock.textInput.minLength = parseInt(object.minLength);
      currentTextBlock.textInput.maxLength = parseInt(object.maxLength);
      currentTextBlock.textInput.isRequired = object.isRequired === 'true';
      currentTextBlock.textInput.maxLines = parseInt(object.maxLines);
      currentTextBlock.textInput.regexChoice = object.regexChoice;

      continue;
    }

    if (object.technicalName === 'option') {
      const newCustomizationOption = new CustomizationOption();
      newCustomizationOption.name = object.label;
      newCustomizationOption.overlayImageUrl = object['overlayImage.imageUrl'];
      newCustomizationOption.thumbnailImageUrl = object['thumbnailImage.imageUrl'];
      newCustomizationOption.additionalCost = parseFloat(object['additionalCost.amount']);

      currentCustomizeComponent.options[object.id] = newCustomizationOption;

      continue;
    }

    if (currentTextBlock) {
      currentTextBlock.name = object.technicalName;
      currentTextBlock.isFreePlacement = object.isFreePlacement === 'true';
      currentTextBlock.width = parseInt(object['dimension.width']);
      currentTextBlock.height = parseInt(object['dimension.height']);
      currentTextBlock.x = parseInt(object['position.x']);
      currentTextBlock.y = parseInt(object['position.y']);

      currentSurface.textBlocks[object.id] = currentTextBlock;
      prevTextBlock = currentTextBlock;
      currentTextBlock = null;
    } else {
      currentCustomizeComponent = new Customization();
      currentCustomizeComponent.name = object.technicalName;
      currentCustomizeComponent.label = object.label;
      currentCustomizeComponent.isRequired = object.isRequired === 'true';
      currentCustomizeComponent.options = {};

      currentSurface.customizations[object.id] = currentCustomizeComponent;
    }
  }

  return product;
}
