import { css } from 'lit';
export const styles = css`
  * {
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
  }

  :host {
    --primary-brand-color: #f39324;
  }

  button,
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #efefef;
    outline: none;
    font-size: 15pt;
    padding: 0.25em;
    border-radius: 0.25em;
    margin: 0.25em;
    border: 1px solid #ccc;
    text-decoration: none;
    color: #333;
  }

  button.submit,
  .button.submit {
    background-color: gold;
  }

  button:disabled {
    background-color: #ccc;
    color: #999;
    cursor: default;
  }

  button:hover {
    background-color: #ddd;
  }

  input {
    border: 1px solid #333;
    border-radius: 0.25em;
    padding: 0.25em;
  }
`;
