import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('font-option-component')
export class FontOptionComponent extends LitElement {
  @property()
  fontOptionId: string;

  private _family: string;

  @property()
  get family(): string {
    return this._family;
  }

  set family(value: string) {
    this._family = value;
    this.style.fontFamily = `'${this._family}'`;
  }

  @property()
  name: string;

  @property()
  fontUrl: string;

  @property({
    reflect: true,
    type: Boolean,
  })
  selected: boolean;

  render() {
    return html`${this.family}`;
  }

  static get styles() {
    return css`
      :host {
        display: block;
        padding: 0.25em 1em;
        cursor: pointer;
        font-size: 20px;
        line-height: 20px;
      }

      :host([selected]) {
        background-color: #ccc;
      }

      :host(:hover) {
        background-color: #dedede;
      }
    `;
  }
}
