import '@polymer/iron-icons';
import { html, LitElement, TemplateResult, css } from 'lit';
import { customElement, property, queryAssignedNodes } from 'lit/decorators.js';

import './customization-option.component';

import { styles } from '../styles';
import { CustomizationOptionComponent } from './customization-option.component';

@customElement('customization-component')
export class CustomizationComponent extends LitElement {
  @property({
    reflect: true,
  })
  customizationId: string;

  @property()
  name: string;

  @property()
  label: string;

  @property()
  selected: string;

  @property({
    reflect: true,
    type: Boolean,
  })
  closed = false;

  @property({
    type: Boolean,
  })
  required = false;

  @property({
    reflect: true,
    type: Boolean,
  })
  markInvalid: boolean;

  private _valid = false;

  @property({
    reflect: true,
    type: Boolean,
  })
  get valid(): boolean {
    return this._valid;
  }

  @queryAssignedNodes({ slot: 'options' })
  customizationOptions: CustomizationOptionComponent[];

  get selectedOption(): CustomizationOptionComponent {
    return this.customizationOptions.find(
      (option) => option.optionId === this.selected,
    );
  }

  validate(): boolean {
    this._valid = true;

    if (this.required && !this.selected)
      this._valid = false;

    this.requestUpdate('valid');

    return this._valid;
  }

  @property({
    reflect: true,
  })
  get isValid(): boolean {
    if (this.required && !this.selected)
      return false;

    return true;
  }

  open() {
    this.closed = false;
    this.dispatchEvent(new Event('opened', { bubbles: true }));
  }

  close() {
    this.closed = true;
    this.dispatchEvent(new Event('closed', { bubbles: true }));
  }

  select(value: string) {
    const targetOption = this.customizationOptions.find((option) => option.name === value);

    if (targetOption) {
      const selectedOptions = this.customizationOptions.filter(
        (option) => option.selected,
      );

      selectedOptions.forEach((option) => {
        option.selected = false;
      });

      this.selected = targetOption.optionId;
      const selectedOption = this.customizationOptions.find(
        (option) => option.optionId === targetOption.optionId,
      );

      selectedOption.selected = true;

      this.dispatchEvent(new Event('change', { bubbles: true }));
      this.close();
    }
  }

  private onHeaderClicked() {
    if (!this.closed) this.close();
    else this.open();
  }

  private onClearOptionClicked() {
    this.selected = null;

    const selectedOptions = this.customizationOptions.filter(
      (option) => option.selected,
    );

    selectedOptions.forEach((option) => {
      option.selected = false;
    });

    this.dispatchEvent(new Event('change', { bubbles: true }));
    this.close();
  }

  private onOptionClicked(event: Event) {
    const target = event.target as CustomizationOptionComponent;

    const selectedOptions = this.customizationOptions.filter(
      (option) => option.selected,
    );

    selectedOptions.forEach((option) => {
      option.selected = false;
    });

    this.selected = target.optionId;
    const selectedOption = this.customizationOptions.find(
      (option) => option.optionId === target.optionId,
    );

    selectedOption.selected = true;

    this.dispatchEvent(new Event('change', { bubbles: true }));
    this.close();
  }

  private renderSelectedPreview() {
    if (this.selected) {
      const selectedOption = this.customizationOptions.find(
        (option) => option.optionId === this.selected,
      );

      return html`
        <div id="selectedPreview">
          <label>${selectedOption.name}</label>
          <img src=${selectedOption.thumbnailImageUrl} />
        </div>
      `;
    }
  }

  private renderClearOption() {
    if (!this.required) {
      return html`
        <customization-option
          ?selected=${!this.selected}
          name="Keine"
          @click=${this.onClearOptionClicked}
        ></customization-option>
      `;
    }
  }

  private renderValidationInfo(): TemplateResult {
    let element = html``;
    if (this.valid && this.selectedOption) {
      element = html`<span id="validationInfo" class="valid" ><iron-icon icon="done"></iron-icon></span>`;
    } else {
      if (this.markInvalid && !this.valid) {
        element = html`<span id="validationInfo" class="invalid" ><iron-icon icon="warning"></iron-icon></span>`;
      }
    }

    return element;
  }

  render(): TemplateResult {
    return html`
      <div id="header" title=${this.label} @click=${this.onHeaderClicked}>
        <iron-icon id="openIndicator" icon="expand-more"></iron-icon>
        <label id="title">
          ${this.label}
          <span class="requirementInfo">
            ${this.required ? '*' : '(optional)'}
          </span>
        </label>
        ${this.renderSelectedPreview()}
        ${this.renderValidationInfo()}
      </div>
      <div id="optionsWrapper">
        ${this.renderClearOption()}
        <slot name="options" @click=${this.onOptionClicked}></slot>
      </div>
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          display: block;
          margin: 0.5em;
          user-select: none;
          background-color: white;
          border: 1px solid transparent;
        }

        :host(:hover) {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }

        :host([closed]) #optionsWrapper {
          display: none;
        }

        :host([markInvalid]:not([valid])) {
          border: 1px solid crimson;
          animation: invalidBlink 1 1s;
        }

        #header {
          display: flex;
          align-items: center;
          font-size: 15pt;
          cursor: pointer;
          position: sticky;
          top: 0;
          background: white;
        }

        #header * {
          pointer-events: none;
        }

        #header > * {
          padding: 0.25em;
        }

        #header #title {
          flex-grow: 1;
        }

        #validationInfo {
          align-self: stretch;
          display: flex;
          align-items: center;
        }

        #validationInfo.valid {
          background-color: green;
          color: white;
        }

        #validationInfo.invalid {
          background-color: crimson;
          color: white;
        }

        .requirementInfo {
          color: #555;
        }

        #header #openIndicator {
          padding: 0;
          flex-shrink: 0;
        }

        :host([closed]) #header #openIndicator {
          transform: rotate(-90deg);
        }

        #optionsWrapper {
          display: flex;
          flex-wrap: wrap;
          padding: 0.5em;
        }

        #selectedPreview {
          display: flex;
          align-items: center;
          margin: 0 2em;
          flex-grow: 1;
          justify-content: end;
        }

        #selectedPreview img {
          max-height: 1em;
          max-width: 1em;
        }

        #selectedPreview label {
          margin: 0 0.25em;
        }

        @media screen and (max-width: 800px) {
          #optionsWrapper {
            flex-wrap: nowrap;
            overflow: auto;
          }

          #optionsWrapper customization-option {
            flex-shrink: 0;
          }
        }

        @keyframes invalidBlink {
          0%,
          50%,
          100% {
            background-color: white;
          }
          25%,
          75% {
            background-color: crimson;
          }
        }
      `,
    ];
  }
}
