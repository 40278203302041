import { Type } from 'class-transformer';
import { IsOptional, IsString, ValidateNested } from 'class-validator';

import { Position } from '../position';
import { Dimension } from '../dimension';

export class Area {
  @IsString()
  customizationType: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => Dimension)
  Dimensions?: Dimension;

  @IsOptional()
  @ValidateNested()
  @Type(() => Position)
  Position?: Position;

  @IsString()
  name: string;

  @IsOptional()
  svgImage?;

  @IsOptional()
  @IsString()
  colorName?: string;

  @IsOptional()
  @IsString()
  fill?: string;

  @IsOptional()
  @IsString()
  fontFamily?: string;

  @IsString()
  label: string;

  @IsOptional()
  @IsString()
  text?: string;

  @IsOptional()
  @IsString()
  optionValue?: string;
}
