import {
  customElement,
  property,
  queryAssignedNodes,
  state,
} from 'lit/decorators.js';
import * as Konva from 'konva/lib';
import { html, LitElement, css, TemplateResult } from 'lit';

import './customization.component';

import { styles } from '../styles';
import { TextBlockComponent } from './text-block.component';
import { CustomizationComponent } from './customization.component';

import { konvaImageFromURL } from '@app/shared/util';
import { Area, CustomizedSurface } from '@app/shared';

@customElement('surface-component')
export class SurfaceComponent extends LitElement {
  @property()
  surfaceId: string;

  @property()
  prevSurfaceId: string;

  @property()
  prevSurfaceName: string;

  @property({
    reflect: true,
    type: Boolean,
  })
  prevSurfaceValid: boolean;

  @property()
  nextSurfaceId: string;

  @property()
  nextSurfaceName: string;

  @property({
    reflect: true,
    type: Boolean,
  })
  nextSurfaceValid: boolean;

  @property()
  name: string;

  @property()
  label: string;

  @property()
  baseImageUrl: string;

  @state()
  private selectedOptions: {
    [customizationId: string]: string;
  } = {};

  @queryAssignedNodes({ slot: 'customizations' })
  components: CustomizationComponent[];

  @queryAssignedNodes({ slot: 'textBlocks' })
  textBlocks: TextBlockComponent[];

  @state()
  private openedCustomization: string;

  private _markInvalid: boolean;

  @property({
    reflect: true,
    type: Boolean,
  })
  get markInvalid(): boolean {
    return this._markInvalid;
  }

  set markInvalid(val: boolean) {
    this._markInvalid = val;
    this.textBlocks.forEach((textBlock) => (textBlock.markInvalid = val));
    this.components.forEach((component) => (component.markInvalid = val));
    this.requestUpdate('markInvalid');
  }

  validate(): boolean {
    const invalidComponents = this.components.map((component) =>
      component.validate(),
    );
    const invalidTextBlocks = this.textBlocks.map((textBlock) =>
      textBlock.validate(),
    );

    return (
      !invalidComponents.includes(false) && !invalidTextBlocks.includes(false)
    );
  }

  generateOutput(): CustomizedSurface {
    const surface = new CustomizedSurface();

    const options = this.components.map<Area>((component) => ({
      customizationType: 'Options',
      name: component.name,
      label: component.label,
      optionValue: component.selectedOption?.name || '',
    }));

    const textAreas = this.textBlocks.map<Area>((textBlock) => ({
      customizationType: 'TextPrinting',
      Dimensions: {
        height: textBlock.height,
        width: textBlock.width,
      },
      Position: {
        x: textBlock.x,
        y: textBlock.y,
      },
      name: textBlock.name,
      colorName: textBlock.colorChooser[0].selectedOption.name,
      fill: textBlock.colorChooser[0].selectedOption.value,
      fontFamily: textBlock.fontChooser[0].selectedOption.family,
      label: textBlock.textInput[0].name,
      text: textBlock.text,
    }));

    surface.areas = [...options, ...textAreas];
    surface.name = this.name;

    return surface;
  }

  async renderPreviewImage() {
    const container = document.createElement('div');
    // @ts-ignore
    const stage = new Konva.Stage({
      container,
      width: 400,
      height: 400,
    });

    // @ts-ignore
    const layer = new Konva.Layer();
    stage.add(layer);

    if (this.baseImageUrl) {
      const image = await konvaImageFromURL(this.baseImageUrl);
      image.size({ width: 400, height: 400 });
      layer.add(image);
    }

    for (const component of this.components) {
      if (component.selectedOption?.overlayImageUrl) {
        const image = await konvaImageFromURL(
          component.selectedOption.overlayImageUrl,
        );
        image.size({ width: 400, height: 400 });
        layer.add(image);
      }
    }

    for (const textBlock of this.textBlocks) {
      // @ts-ignore
      const text = new Konva.Text({
        fontFamily: textBlock.fontName,
        fontSize: textBlock.calculateFontSize() * 0.9,
        text: textBlock.text,
        width: textBlock.width,
        height: textBlock.height,
        align: 'center',
        verticalAlign: 'bottom',
        fill: textBlock.colorChooser[0]?.selectedOption.value,
        x: textBlock.x,
        y: textBlock.y,
      });

      layer.add(text);
    }

    return stage.toDataURL();
  }

  firstUpdated() {
    if (this.components.length <= 0) {
      this.textBlocks[0]?.open();
    } else {
      this.components[0].open();
    }
  }

  private onCustomizationChanged(event: Event) {
    const target = event.target as CustomizationComponent;

    this.selectedOptions[target.customizationId] = target.selected;
    this.validate();

    this.requestUpdate();
  }

  private onTextBlockChanged() {
    this.validate();

    this.requestUpdate();
  }

  private async onCustomizationOpened(event: Event) {
    const target = event.target as CustomizationComponent | TextBlockComponent;

    const openedComponents = this.components.filter(
      (component) =>
        !component.closed &&
        component.customizationId !==
          (target as CustomizationComponent).customizationId,
    );

    const openedTextBlocks = this.textBlocks.filter(
      (textBlock) =>
        !textBlock.closed &&
        textBlock.textBlockId !== (target as TextBlockComponent).textBlockId,
    );

    openedComponents.forEach((component) => (component.closed = true));
    openedTextBlocks.forEach((textBlock) => (textBlock.closed = true));

    await this.updateComplete;

    const customizationsWrapper = this.shadowRoot.querySelector(
      '#customizationsWrapper',
    );

    customizationsWrapper.scrollTo({
      top: target.offsetTop,
      behavior: 'smooth',
    });
  }

  private onCustomizationClosed() {
    const openedComponents = this.components.filter(
      (component) => !component.closed,
    );

    if (openedComponents.length <= 0) this.openedCustomization = null;
  }

  private onBuPrevSurfaceClicked() {
    this.dispatchEvent(new Event('prevSurfaceClicked'));
  }

  private onBuNextSurfaceClicked() {
    this.dispatchEvent(new Event('nextSurfaceClicked'));
  }

  private onTextBlockFocused() {
    this.components.forEach((component) => (component.closed = true));
  }

  private renderPreview() {
    const images = [];

    if (this.baseImageUrl) images.push(html`<img src=${this.baseImageUrl} />`);

    for (const component of this.components) {
      if (component.selectedOption?.overlayImageUrl) {
        images.push(
          html`<img
            src=${component.selectedOption.overlayImageUrl}
            ?opened=${component.customizationId === this.openedCustomization}
          />`,
        );
      } else {
        images.push(html`<div class="imagePlaceholder"></div>`);
      }
    }

    for (const textBlock of this.textBlocks) {
      const fontName = textBlock.fontChooser[0]?.selectedOption?.family;
      const colorValue = textBlock.colorChooser[0]?.selectedOption?.value;

      images.push(html`<div class="textLayer">
        <div
          class="textBlock"
          style="font-family: '${fontName}'; color: ${colorValue}; width: ${
        textBlock.width
      }px; height: ${textBlock.height}px; top: ${textBlock.y}px; left: ${
        textBlock.x
      }px; font-size: ${textBlock.calculateFontSize()}px;"
        >
          ${textBlock.textInput[0].value}
        </div>
      </div>`);
    }

    return images;
  }

  private renderNavigationPrevButton(): TemplateResult {
    let element = html`<div></div>`;

    if (this.prevSurfaceId) {
      element = html`
        <button
          class="buPrevSurface"
          @click=${this.onBuPrevSurfaceClicked}
        >
          <iron-icon icon="arrow-back"></iron-icon>
          ${this.prevSurfaceName}
        </button>
      `;
    }

    return element;
  }

  private renderNavigationNextButton(): TemplateResult {
    let element = html`<div></div>`;

    if (this.nextSurfaceId) {
      element = html`
        <button
        class="buNextSurface"
        @click=${this.onBuNextSurfaceClicked}
        >
          ${this.nextSurfaceName}
          <iron-icon icon="arrow-forward"></iron-icon>
        </button>
      `;
    }

    return element;
  }

  private renderValidityIndicator(): TemplateResult {
    if ('') {
      return html`
        <span id="validationIndicator">
          <iron-icon icon="done"></iron-icon>Personalisiert
        </span>
      `;
    }
  }

  render() {
    return html`
      <div id="previewWrapper">
        <div id="canvas">${this.renderPreview()}</div>
        <label id="previewInfoText">
          Hinweis: Die Anzeige ist eine ungefähre Vorschau.
        </label>
      </div>
      <div id="controlsWrapper">
        <div id="header">
          ${this.renderNavigationPrevButton()}
          <label id="title">${
            this.label
          }${this.renderValidityIndicator()}</label>
          ${this.renderNavigationNextButton()}
        </div>
        <div id="customizationsWrapper">
          <slot
            name="customizations"
            @change=${this.onCustomizationChanged}
            @opened=${this.onCustomizationOpened}
            @closed=${this.onCustomizationClosed}
            ?markInvalid=${this.markInvalid}
          ></slot>
          <slot
            name="textBlocks"
            @change=${this.onTextBlockChanged}
            @opened=${this.onCustomizationOpened}
            @closed=${this.onCustomizationClosed}
            @focus=${this.onTextBlockFocused}
            ?markInvalid=${this.markInvalid}
          ></slot>
        </div>
      </div>`;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          display: grid;
          background-color: white;
          user-select: none;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 100%;
          z-index: 99;
        }

        :host([markInvalid]:not([nextSurfaceValid])) .buNextSurface,
        :host([markInvalid]:not([prevSurfaceValid])) .buPrevSurface {
          border: 1px solid crimson;
          background-color: crimson;
          color: white;
        }

        #previewWrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        #previewInfoText {
          color: #aaa;
        }

        #canvas {
          position: relative;
          width: 400px;
          height: 400px;
          margin: 1em;
        }

        #canvas img,
        #canvas .textLayer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        #canvas .textLayer .textBlock {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
        }

        #header {
          display: grid;
          justify-content: space-between;
          font-size: 20pt;
          padding: 0.25em;
          grid-template-columns: 1fr 1fr 1fr;
        }

        #title {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        #validationIndicator {
          display: flex;
          align-items: center;
          font-size: 10pt;
          color: green;
          padding: 0 0.25em;
        }

        #controlsWrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          border-left: 1px solid #ccc;
        }

        #customizationsWrapper {
          flex-grow: 1;
          overflow: auto;
          background-color: #efefef;
          position: relative;
        }

        #customizationsWrapper > * {
          position: relative;
        }

        @media screen and (max-width: 800px) {
          :host {
            grid-template-columns: 100%;
            grid-template-rows: 1fr minmax(0, 2fr);
          }

          #customizationsWrapper {
            padding-bottom: 200px;
          }

          #controlsWrapper {
            border-top: 1px solid #ccc;
          }

          #title {
            font-size: 12pt;
          }
        }

        @media screen and (max-height: 800px) {
          #canvas {
            height: 200px;
            width: 200px;
          }

          #canvas .textLayer {
            transform-origin: top left;
            transform: scale(0.5);
          }
        }
      `,
    ];
  }
}
