export * from './area';
export * from './customized-surface';

import {
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';

import { CustomizedSurface } from './customized-surface';

export class CustomizeOutput {
  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => CustomizedSurface)
  surfaces: CustomizedSurface[];

  @IsOptional()
  @IsString()
  templateName?: string;

  @IsOptional()
  @IsString()
  fileName?: string;

  @IsBoolean()
  stop: boolean;
}
