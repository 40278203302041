import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { styles } from '../styles';

@customElement('surface-preview-component')
export class SurfacePreviewComponent extends LitElement {
  @property()
  name: string;

  @property()
  imageUrl: string;

  render() {
    return html`
      <img src=${this.imageUrl} />
      <label>${this.name}</label>
    `;
  }

  static get styles() {
    return [
      styles,
      css`
        :host {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0.25em;
          margin: 0.25em;
          background-color: white;
          border-radius: 0.25em;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }

        @media screen and (max-width: 500px) {
          img {
            width: 200px;
            height: 200px;
          }
        }
      `,
    ];
  }
}
